import React, { useEffect, useState } from "react";
import Header from "./Layout/header";
import Footer from "./Layout/footer";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CartSelector, clearPdfData, generatePdf, getOrders } from "../store/reducer/CartReducer";
import dayjs from "dayjs";
import { ORDER_STATUS } from "../utils/Constant";
import { API_BASE, assestURL } from "../services/config";

const MyOrders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderData, generateLoading, pdfMessage } = useSelector(CartSelector);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setlimit] = useState(0);
  useEffect(() => {
    dispatch(getOrders({ query, limit, page }));
  }, []);


  useEffect(() => {
    console.log(generateLoading, "generateLoading");
    if (generateLoading === "Fulfilled") {
      window.open(`${assestURL}${pdfMessage}`, "_blank", "noreferrer");
    }
    dispatch(clearPdfData());
  }, [pdfMessage]);
  console.log(orderData, "orderData");
  return (
    <div>
      <Header />
      <section class="user_dashboard_pages">
        <div class="container">
          <div class="row innerpage user_dashboard space-between align-flex-start">
            <div class="col-md-3 col-xl-3 col-lg-3 col-sm-12 col-xs-12">
              <div class="myaccounts_sidebar white_bg">
                <div class="account_nav">
                  <ul>
                    <li>
                      <Link to={"/my_account"}>My Account</Link>
                    </li>
                    <li>
                      <Link to={"/my_orders"}>My Orders</Link>
                    </li>
                    <li>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          localStorage.clear();
                          navigate("/");
                        }}
                      >
                        Log Out
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-md-9 col-xl-9 col-lg-9 col-sm-12 col-xs-12">
              <div class="dashboard_area white_bg">
                <div class="col-md-12">
                  <div class="myaccount_edit flex align-center">
                    <div class="left">
                      <h3 class="sub_heading">My Orders</h3>
                    </div>
                  </div>

                  <div class="question_lists">
                    {orderData &&
                      orderData.map((orders) => {
                        console.log(orders, "ORDERSSSS");
                        return (
                            <><hr />
                            <div class="listss d-flex justify-content-between">
                           <div className="col-6">
                            <h4 className="p-0">ORDER NO: {orders?.order_no? orders?.order_no:"-"}</h4>
                            <p>ORDER DATE: {dayjs(orders?.created_at).format('DD/MM/YYYY')}</p>
                            <p>Total Price : ₹ {parseFloat(orders?.total_price ??orders?.sub_total).toFixed(2)}</p>

                            </div><div
                            className="text-end col-4 d-flex justify-content-end align-items-center">
                            <i class="icofont icofont-print print-icon px-3" title="Print"
                            onClick={() =>
                              dispatch(generatePdf(orders?.id))
                            }></i>
                            </div>

                            <p href={null} class="re-order1">
                              {ORDER_STATUS[orders?.order_status]}
                            </p>
                          </div>
                            {orders?.orderdetails?.map((val) => {
                              return (
                                <>
                                 <div class="liststyle d-flex">
                                <div class="leftimg">
                                  <img
                                    src={API_BASE + val?.products?.image}
                                    alt=""
                                  />
                                </div>
                                  {/* <div class="raight"> */}
                                  <div>
                                  <h4>{val?.products?.name}</h4>
                                  </div>
                                  <span class="qaty">
                                    <b> Quantity :</b> {val?.quantity}
                                  </span>
                                  <span class="datae">
                                    <b>Price : </b>
                                    ₹ {val?.sale_price}
                                  </span>
                                {/* </div> */}
                                </div>
                                </>
                              );
                            })}

                          
                         
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default MyOrders;
