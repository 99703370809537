import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constant";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";
import { secretKey } from "../../services/config";
import { banners, findUserDetails, getSettings, sendMail, sendSurvey, updateMyaccount } from "../../services/api";

const namespace = "HomeReducer";

const initialState = {
    loading: "initial",
    errorMessage: "",
    productLoading: "initial",
    categoryLoading: "initial",
    settingLoader: "initial",
    myAccountLoader: "initial",
    getUserLoader: "initial",
    SurveyLoader:"initial",
    ContactUsLoader: "initial",
    count: 0,
    bannerdata: [],
    productData: [],
    settingDatas: [],
    accounDetails: [],
    userData: [],
    successMessage: "",
    surveysuccessMessage: ""
};

export const bannerList = createAsyncThunk(
    `${namespace}/bannerList`,
    async ({ page, limit, query }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await banners(page, limit, query);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const settingData = createAsyncThunk(
    `${namespace}/settingsData`,
    async ({ }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await getSettings();
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
)

export const MyAccountDetails = createAsyncThunk(
    `${namespace}/myAccountDetails`,
    async ({ formData }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await updateMyaccount(formData);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const getUser = createAsyncThunk(
    `${namespace}/getUser`,
    async (id, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await findUserDetails();
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
)

export const ContactUs = createAsyncThunk(
    `${namespace}/ContactUs`,
    async ({ formData }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await sendMail(formData);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
)
export const Surveys = createAsyncThunk(
    `${namespace}/Surveys`,
    async ({ formData }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await sendSurvey(formData);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
)


const HomeSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearMessages: (state) => {
            state.successMessage = "";
            state.myAccountLoader = "initial";
        }
    },
    extraReducers: (builder) => {
        builder.addCase(bannerList.pending, (state) => {
            state.bannerLoader = API_STATUS.PENDING;
        })
        builder.addCase(bannerList.fulfilled, (state, { payload }) => {
            state.bannerLoader = API_STATUS.FULFILLED;
            console.log(payload, secretKey, 'payloaddatas');
            let payloaddatas =
                JSON.parse(EncryptDecrypt.decryptdata(payload.data, secretKey))

            state.bannerdata = payloaddatas?.data;
        })
        builder.addCase(bannerList.rejected, (state, action) => {
            state.bannerLoader = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        })
        builder.addCase(settingData.pending, (state) => {
            state.settingLoader = API_STATUS.PENDING;
        })
        builder.addCase(settingData.fulfilled, (state, { payload }) => {
            state.settingLoader = API_STATUS.FULFILLED;
            console.log(payload, secretKey, 'payloaddatas');
            let payloaddatas =
                JSON.parse(EncryptDecrypt.decryptdata(payload.datas, secretKey))
            console.log(payloaddatas, 'payloaddatasyyy')
            state.settingDatas = payloaddatas[0];
        })
        builder.addCase(settingData.rejected, (state, action) => {
            state.settingLoader = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        })
        builder.addCase(MyAccountDetails.pending, (state) => {
            state.myAccountLoader = API_STATUS.PENDING;
        })
        builder.addCase(MyAccountDetails.fulfilled, (state, { payload }) => {
            state.myAccountLoader = API_STATUS.FULFILLED;
        })
        builder.addCase(MyAccountDetails.rejected, (state, action) => {
            state.myAccountLoader = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        })
        builder.addCase(getUser.pending, (state) => {
            state.getUserLoader = API_STATUS.PENDING;
        })
        builder.addCase(getUser.fulfilled, (state, { payload }) => {
            state.getUserLoader = API_STATUS.FULFILLED;
            console.log(payload, 'payloadYyyyy')
            let payloaddatas =
                JSON.parse(EncryptDecrypt.decryptdata(payload.data, secretKey))
            state.userData = payloaddatas;
        })
        builder.addCase(getUser.rejected, (state, action) => {
            state.getUserLoader = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        })
        builder.addCase(ContactUs.pending, (state) => {
            state.ContactUsLoader = API_STATUS.PENDING;
        })
        builder.addCase(ContactUs.fulfilled, (state, { payload }) => {
            state.ContactUsLoader = API_STATUS.FULFILLED;
            state.successMessage = payload.message;
            // alert(JSON.stringify(payload))
        })
        builder.addCase(ContactUs.rejected, (state, action) => {
            state.ContactUsLoader = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        })
        builder.addCase(Surveys.pending, (state) => {
            state.SurveyLoader = API_STATUS.PENDING;
        })
        builder.addCase(Surveys.fulfilled, (state, { payload }) => {
            state.SurveyLoader = API_STATUS.FULFILLED;
            state.surveysuccessMessage = payload.message;
            // alert(JSON.stringify(payload))
        })
        builder.addCase(Surveys.rejected, (state, action) => {
            state.SurveyLoader = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        })
    },
});

export const { clearData, clearMessages } = HomeSlice.actions;

export const homeSelector = (state) => state.HomeReducer;

export default HomeSlice.reducer;
