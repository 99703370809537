import React, { useEffect } from 'react'
import Header from './Layout/header'
import Footer from './Layout/footer'
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { contactUsValidationSchema } from '../utils/Validation'
import { useDispatch, useSelector } from 'react-redux';
import { ContactUs, clearMessages, homeSelector, settingData } from '../store/reducer/HomeReducer';
import { toast } from 'react-toastify';

const Contact = () => {
  const dispatch = useDispatch()
  const { successMessage } = useSelector(homeSelector);
  const { settingDatas } = useSelector(homeSelector);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(contactUsValidationSchema),
  });
  const submitHanlder = formData => {
    console.log(formData)
    return false;
    dispatch(ContactUs({ formData }))
  }

  useEffect(() => {
    if (successMessage !== "") {
      toast.success(successMessage)
      dispatch(clearMessages())
      reset()
    }
  }, [successMessage])

  useEffect(() => {
    dispatch(settingData({}))
  }, [])


  return (
    <div>
      <Header />
      <div class="hero_section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="herosection_content">
                <h2>Contact Us</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="contact-form">
        <div class="container">
          <h2>Message Us</h2>
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="left-form">
                <form onSubmit={handleSubmit(submitHanlder)}>
                  <label>Name</label><br />
                  <input type="text" placeholder="Your Name..." {...register("name")} /> <br />
                  {errors?.name?.message && (
                    <p className="error">{errors.name.message}</p>
                  )}
                  <label>Email</label><br />
                  <input type="text" placeholder="Your Email..." {...register("email")} /><br />
                  {errors?.email?.message && (
                    <p className="error">{errors.email.message}</p>
                  )}
                  <label>Phone Number</label><br />
                  <input type="number" placeholder="Your Number..." {...register("phone_number")} /><br />
                  {errors?.phone_number?.message && (
                    <p className="error">{errors.phone_number.message}</p>
                  )}
                  <label>Message</label><br />
                  <textarea placeholder="Write Something..." style={{ height: "200px" }} {...register("message")}></textarea><br />
                  <input class="submit-btn" type="submit" value="Submit" />
                </form>
              </div>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="right-form">
                <p><b>Have any queries?</b><br />We're here to help. Your connection to solutions and assistance, available at your convenience.</p>
                <div class="hr"></div>
                <ul><li><i class="lni lni-map-marker"></i><span style={{ display: "flex" }}>{settingDatas?.address}</span></li>
                  <li><i class="lni lni-phone"></i><span>{settingDatas?.contact}</span></li>
                  <li><i class="lni lni-envelope"></i><span>{settingDatas?.gmail}</span></li></ul>
                <iframe class="map-dig" src={settingDatas?.map_url}></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Contact