import React, { useRef, useState, useEffect, useCallback } from "react";
import Logo from "../../assets/img/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { CartSelector, cartProductList } from "../../store/reducer/CartReducer";
import { API_BASE } from "../../services/config";
import AVATAR from "../../assets/img/avatar.webp";
import { toast } from "react-toastify";
import { homeSelector, settingData } from "../../store/reducer/HomeReducer";
import { logOut } from "../../store/reducer/SigninReducer";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Visible = localStorage.getItem("isAuthenticated") ?? false;
  const [isOpen, setIsOpen] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);
  const { cartProductDatas, cartProducttotal, cartLoading } =
    useSelector(CartSelector);
  const { settingDatas } = useSelector(homeSelector);
  useEffect(() => {
    let page = 0;
    let limit = 0;
    let query = "";
    if (localStorage.getItem("isAuthenticated")) {
      dispatch(cartProductList({ page, limit, query }));
    }
    dispatch(settingData({}));
  }, []);

  useEffect(() => {
    console.log(cartProductDatas, "cartProductDatas");
  }, [cartProductDatas]);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const logoutuser = () => {
    dispatch(logOut());
  };
  return (
    <div>
      <header id="full_nav">
        <div class="header">
          <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light">
              <a
                class="navbar-brand"
                style={{ cursor: "pointer" }}
                href={null}
                onClick={() => navigate("/")}
              >
                <img src={Logo} alt="" />
              </a>
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={(e) => {
                  setMobileNav(!mobileNav);
                }}
              >
                <i class="icofont icofont-navigation-menu"></i>
              </button>

              <div
                className={
                  mobileNav
                    ? "collapse show navbar-collapse"
                    : "collapse navbar-collapse"
                }
                id="navbarSupportedContent"
              >
                <ul class="navbar-nav mx-auto">
                  <li class="nav-item active">
                    <Link class="nav-link" to={"/"}>
                      Home
                    </Link>
                  </li>
                  <li class="nav-item active">
                    <Link class="nav-link" to={"/plans"}>
                      Plans
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link" to={"/about_us"}>
                      About
                    </Link>
                  </li>

                  <li class="nav-item">
                    {/* <a class="nav-link" href="products.html">Products</a> */}
                    <Link to={"/products"} class="nav-link">
                      Products
                    </Link>
                  </li>

                  <li class="nav-item">
                    <Link class="nav-link" to={"/contact_us"}>
                      Contact Us
                    </Link>
                  </li>

                  {Visible ? (
                    <></>
                  ) : (
                    <li class="nav-item">
                      {/* <a class="nav-link login_btn" href="login.html">Login</a> */}
                      <Link to={"/signin"} class="nav-link login_btn">
                        {" "}
                        Login
                      </Link>
                    </li>
                  )}
                </ul>

                <div class="header-content">
                  <div class="header_contact text-right">
                    <span>Call Us!</span>
                    <span class="phone_no">{settingDatas?.contact}</span>
                  </div>
                  {Visible ? (
                    <></>
                  ) : (
                    <div class="header_icon">
                      <Link
                        to={"/signin"}
                        onClick={() => {
                          navigate("/signin");
                          toast.warning("Signin to continue...");
                        }}
                      >
                        <i class="icofont icofont-cart"></i>
                      </Link>
                    </div>
                  )}
                </div>
                {Visible ? (
                  <div class="myaccount_menus">
                    <ul>
                      <li class="header_icon">
                        <Link to={"/cart_products"}>
                          <i class="icofont icofont-cart"></i>
                          <span class="cart_no">
                            {cartProductDatas?.length}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <a
                          href={null}
                          className="profile cursor-pointer"
                          onClick={handleOpen}
                        >
                          <img src={AVATAR} alt="" class="profile_img" />
                        </a>
                        <ul
                          class="dropdown"
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          <li>
                            <Link to={"/my_account"}>My Account</Link>
                          </li>
                          <li>
                            <Link to={"/my_orders"}>My Orders</Link>
                          </li>
                          <li>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={logoutuser}
                            >
                              Log Out
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
