import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { bannerList, homeSelector } from "../../store/reducer/HomeReducer";
import { API_BASE } from "../../services/config";
import { Link } from "react-router-dom";
import One from "../../assets/img/banners (1)/1.jpg";
import Two from "../../assets/img/banners (1)/2.jpg";
import Three from "../../assets/img/banners (1)/3.jpg";
import Four from "../../assets/img/banners (1)/4.jpg";
import { Carousel } from "react-bootstrap";
const Banner = () => {
  const dispatch = useDispatch();
  const { bannerdata } = useSelector(homeSelector);
  console.log(bannerdata, "bannerdata");

  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    arrows: false,
    dots: true,
  };
  const [data, setDatas] = useState([]);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState("");
  const [limit, setLimit] = useState("");

  useEffect(() => {
    dispatch(bannerList({ page, limit, query }));
  }, []);

  // useEffect(() => {
  //   const element = bannerdata[0]?.content?.split(",");
  //   const q_ele = bannerdata[0]?.quote?.split(",");
  //   const des_ele = bannerdata[0]?.description?.split(",");
  //   for (let index = 0; index < element?.length; index++) {
  //     let data = {};
  //     data.content = element[index];
  //     data.quote = q_ele[index];
  //     data.description = des_ele[index];
  //     setDatas((prev) => [...prev, data]);
  //   }
  // }, [bannerdata]);
  useEffect(() => {
    let img = [];
    bannerdata?.map((data) => {
      img.push(API_BASE + data?.image);
    });
    setDatas(img)
  }, [bannerdata]);
  console.log(data,'data');
  
  return (
    <>
      {/* <div
        class="full-banner"
        style={{
          background: ` url(${API_BASE}${bannerdata && bannerdata[0]?.image})`,
        }}
      >
        <div class="container">
        
          <Slider {...settings}>
            {data?.map((values, i) => {
              let spl = values.quote.split(" ");
              if (spl.length > 1) {
                spl = [spl[0], spl.slice(1).join(" ")];
              }
             
              return (
                <>
                  <div class="col-md-12">
                    <div class="banner-content">
                      <h1>
                        {spl[0]} <span>{spl[1]}</span>
                      </h1>
                      <h3>{values?.content}</h3>
                      <p>{values?.description}</p>
                      <Link to={"/products"} class="btn">
                        All Products
                      </Link>
                    </div>
                  </div>
                </>
              );
            })}
          </Slider>
         
        </div>
      </div> */}
      <Carousel>
        {data?.map((img)=>{
          return(
            <Carousel.Item interval={1000}>
          <img className="d-block w-100" src={img} alt="First slide" />
        </Carousel.Item>
          )
        })}
        {/* <Carousel.Item interval={1000}>
          <img className="d-block w-100" src={data[0]} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <img className="d-block w-100" src={data[1]} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <img className="d-block w-100" src={data[2]} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <img className="d-block w-100" src={data[3]} alt="First slide" />
        </Carousel.Item> */}
      </Carousel>
    </>
  );
};

export default Banner;
