import React, { useRef, useState, useEffect, useCallback } from "react";
import Header from "../Layout/header";
import Footer from "../Layout/footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import {
  productList,
  productSelector,
  productdataDetails,
  relatedProducts,
} from "../../store/reducer/ProductReducer";
import {
  CartSelector,
  cartProductList,
  removecartProduct,
  addcartProduct,
  clearLoadings,
} from "../../store/reducer/CartReducer";
import debounce from "lodash/debounce";
import _ from "lodash";
import { API_BASE } from "../../services/config";
import Slider from "react-slick";
import { API_STATUS } from "../../utils/Constant";
import { toast } from "react-toastify";
import Select from "react-select";

const ProductDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, category_id } = useParams();
  const [quantity_count, setQuantity] = useState(1);
  const rangeRef = useRef(0);
  const rangeVRef = useRef(0);
  const [selectedOption, setSelectedOption] = useState({});
  const { productIdDatas, relatedProductDatas, productdetailsLoading } =
    useSelector(productSelector);
  const {
    cartProductDatas,
    cartProducttotal,
    cartLoading,
    removecartLoading,
    addcartLoading,
    errorMessage,
  } = useSelector(CartSelector);
  const [options, SetOptions] = useState([]);
  const [defaults, SetDefault] = useState();
  const [sale, SetSale] = useState();
  const [reg, SetReg] = useState();
  const [unit, SetUnit] = useState();

  //     const [query, setQuery] = useState("");
  //     const [page, setPage] = useState("");
  //     const [limit, setLimit] = useState(8);
  //    console.log(options)
  //    useEffect(() => {
  //     dispatch(productList({ query, page, limit }));
  //   }, []);
  useEffect(() => {
    dispatch(productdataDetails({ id }));
    let getData = {};
    getData.category_id = category_id;
    getData.id = id;

    dispatch(relatedProducts({ getData }));
  }, [id]);

  // useEffect(()=>{
  //     console.log(options,"opt")
  // },[options])

  useEffect(() => {
    console.log(relatedProductDatas, "relatedProductDatas");
  }, [relatedProductDatas]);

  const addcartProducts = (productData, type) => {
    console.log(productData, "yyy");

    if (localStorage.getItem("isAuthenticated")) {
      let adddata = [];
      let datas = {};
      datas.product_id = productData.product_id;
      datas.quantity = 1;
      datas.unit_type = productData?.unit_type;
      // datas.quantity = quantity_count.toString();
      datas.category_id = productData.catgory_id;
      datas.reg_price = productData?.reg_price;
      datas.sale_price = productData?.sale_price;
      adddata.push(datas);
      dispatch(addcartProduct({ payload: adddata }));
    } else {
      navigate("/signin");
      toast.warning("Signin to continue...");
    }
  };
  const [selectedData, setSelectedData] = useState({
    unit_type: "",
    reg_price: "",
    sale_price: "",
    product_id: "",
    category_id: "",
  });
  /**  Slider Settings */
  const settings = {
    // normal options...
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    arrows: true,

    // the magic
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };
  /** Product Images */
  const images = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ];

  const decreseQty = () => {
    let QtyCount = quantity_count - 1;
    QtyCount > 0 ? setQuantity(QtyCount) : setQuantity(1);
    console.log(QtyCount, "TOASTS");
  };

  const increseQty = () => {
    let QtyCount = quantity_count + 1;
    setQuantity(QtyCount);
    console.log(QtyCount, "TOASTS");
  };
  const handleSelectChange = (selectedOption, productId) => {
    console.log(selectedOption, productId, "ppp");
    const [unit, regPrice, salePrice] = selectedOption.value.split(",");
    console.log(unit, regPrice, salePrice, "cllsfk");
    setSelectedData({
      reg_price: regPrice,
      sale_price: salePrice,
      unit_type: unit,
      product_id: productId,
      catgory_id: productIdDatas?.catgory_id,
      //   category_id:
    });
    setSelectedOption((prevSelected) => ({
      ...prevSelected,
      [productId]: selectedOption,
    }));
  };
  useEffect(() => {
    if (addcartLoading === API_STATUS.FULFILLED) {
      toast.dismiss();
      toast.success("Product added in the Cart Successfully!");
      let page = 0;
      let limit = 0;
      let query = "";
      dispatch(cartProductList({ page, limit, query }));
      dispatch(clearLoadings());
    }
    if (errorMessage?.message) toast.error(errorMessage?.message);
    else if (errorMessage?.errors) {
      let data = "";
      console.log(errorMessage, "errorMessage");
      errorMessage?.errors.map((err) => {
        data += err.message + " ";
      });
      toast.error(data);
      // dispatch(clearLoadings());
    }
  }, [addcartLoading, errorMessage]);
  useEffect(() => {
    console.log(productIdDatas, "productIdDatas");
    if (productIdDatas?.unit_type) {
      console.log(options, "opt in");
      let opt = productIdDatas.unit_type
        ? productIdDatas.unit_type.map((unit) => {
            const unitLabel = unit < 1000 ? `${unit}g` : `${unit / 1000} kg`;
            const salePricePerGram =
              parseFloat(productIdDatas.sale_price) / 1000;
            const regPricePerGram = parseFloat(productIdDatas.reg_price) / 1000;

            const salePrice = (salePricePerGram * unit).toFixed(2);
            const regPrice = (regPricePerGram * unit).toFixed(2);
            return {
              label: (
                <div className="select-option">
                  <span className="unit-label">{unitLabel}</span>
                  <span className="price">₹{salePrice}</span>
                  <span className="reg-price">₹{regPrice}</span>
                </div>
              ),
              value: `${unit}g,${regPrice},${salePrice} `,
            };
          })
        : [];
      SetOptions(opt);
      // let defaultm = [];
      // defaultm.push(opt[0]);
      console.log(opt[0], "opt[0]");
      // SetDefault(defaultm)
      const [unit, regPrice, salePrice] = opt[0].value.split(",");

      setSelectedData({
        reg_price: regPrice,
        sale_price: salePrice,
        unit_type: unit,
        product_id: id,
        catgory_id: category_id,
        //   category_id:
      });
      setSelectedOption((prevSelected) => ({
        ...prevSelected,
        [id]: opt[0],
      }));
    }
  }, [productIdDatas]);
  useEffect(() => {
    if (defaults) {
      const [unit, regPrice, salePrice] = defaults[0].value.split(",");
      SetSale(salePrice);
      SetReg(regPrice);
      SetUnit(unit);
      console.log(defaults, "defaults");
    }
  }, [defaults]);

  return (
    <>
      <Header />
      <div class="hero_section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="herosection_content">
                <h2>Product Detail</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- SECTION START  --> */}

      <section class="pt-80">
        {/* <!--  PRODUCT DETAILS PART START  --> */}
        <div class="product_details">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <div class="product_img d-flex">
                  {/* <ImageGallery items={images} /> */}

                  <div class="small_img">
                    <img
                      src={API_BASE + productIdDatas?.image}
                      class="w-100 img-fluid"
                      alt=""
                    />
                    <img
                      src={API_BASE + productIdDatas?.image}
                      class="w-100 img-fluid"
                      alt=""
                    />
                    <img
                      src={API_BASE + productIdDatas?.image}
                      class="w-100 img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="big_img">
                    <img
                      src={API_BASE + productIdDatas?.image}
                      class="w-100 img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <div class="producudetails_content">
                  <h3>{productIdDatas?.name}</h3>
                  <strong>
                    ₹
                    {selectedData?.sale_price ? selectedData?.sale_price : sale}{" "}
                  </strong>
                  <strong>
                    <del>
                      ₹{selectedData?.reg_price ? selectedData?.reg_price : reg}{" "}
                    </del>
                  </strong>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: productIdDatas?.featured,
                    }}
                  />
                  <div class="add_to_cart d-flex">
                    <div class="number-input">
                      <div className="dropdown-container">
                        <Select
                          //  value={selectedOption}
                          defaultValue={defaults}
                          options={options}
                          value={selectedOption[productIdDatas?.id]}
                          onChange={(option) =>
                            handleSelectChange(option, productIdDatas?.id)
                          }
                          isSearchable={false}
                          classNamePrefix="react-select"
                          placeholder="Select Package"
                          className="react-select"
                        />
                      </div>
                      {/* <Select
                                options={options}
                                value={sel} */}
                    </div>
                    <div class="grams"></div>
                    <button
                      class="btn custom-btn btn border-transparent ml-5"
                      onClick={() => addcartProducts(selectedData, 1)}
                    >
                      Add Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- PRODUCT DETAILS PART END --> */}

        {/* <!-- PRODUCT TABBER START --> */}
        <div class="product_tabber">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <ul class="nav nav-tabs nav_custom" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link nav-link-custom active"
                      id="description-tab"
                      data-toggle="tab"
                      href="#description"
                      role="tab"
                      aria-controls="description"
                      aria-selected="true"
                    >
                      DESCRIPTION
                    </a>
                  </li>
                  {/* <!-- <li class="nav-item" role="presentation">
                                        <a class="nav-link nav-link-custom" id="information-tab" data-toggle="tab" href="#information" role="tab" aria-controls="information" aria-selected="false">INFORMATION</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link nav-link-custom" id="review-tab" data-toggle="tab" href="#review" role="tab" aria-controls="review" aria-selected="false">REVIEW</a>
                                    </li> --> */}
                </ul>

                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="description"
                    role="tabpanel"
                    aria-labelledby="description-tab"
                    dangerouslySetInnerHTML={{
                      __html: productIdDatas?.featured,
                    }}
                  />

                  {/* <!-- <div class="tab-pane fade" id="information" role="tabpanel" aria-labelledby="information-tab">

                                    </div>
                                    <div class="tab-pane fade" id="review" role="tabpanel" aria-labelledby="review-tab">

                                    </div> --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- PRODUCT TABBER END -->

                <!-- BEST SELLER PART START --> */}
        <div class="full-bestSeller">
          <div class="container">
            <div class="row">
              <div class="col-12 text-left">
                <h3 class="section-title">Related Product</h3>
              </div>
            </div>

            {/* <div class="row mt-2"> */}
            <Slider {...settings} className="product-slider">
              {relatedProductDatas &&
                relatedProductDatas.map((row, index) => {
                  return (
                    <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12 mb-12">
                      <div class="product">
                        <div class="product-img">
                          <img
                            class="w-100"
                            src={API_BASE + row?.image}
                            alt=""
                          />
                        </div>
                        <div class="product-content">
                          <div class="product-details">
                            <h3 class="product-name">
                              <Link
                                to={`/product_details/${row?.id}/${row?.catgory_id}`}
                              >
                                {" "}
                                {row?.name}
                              </Link>
                            </h3>
                            <span class="product-prev-price">
                              ₹{row?.reg_price}
                            </span>
                            <span class="product-price">
                              ₹{row?.sale_price}
                            </span>
                          </div>
                          <div class="buttons">
                            {row?.trash == 1 ? (
                              <span class="sold-out-tag position-top-right">
                                {" "}
                                "Sold out"{" "}
                              </span>
                            ) : (
                              <></>
                            )}
                            <button
                              class="btn custom-btn position-bottom-right"
                              onClick={() => addcartProducts(row, 2)}
                            >
                              {" "}
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider>

            {/* </div> */}
          </div>
        </div>

        {/* <!-- BEST SELLER PART END  --> */}
      </section>

      {/* <!-- SECTION END --> */}
      <Footer />
    </>
  );
};

export default ProductDetails;
