import React, { useRef, useState, useEffect, useCallback } from "react";
import Header from "../Layout/header";
import Footer from "../Layout/footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  CartSelector,
  addcartProduct,
  cartProductList,
  clearLoadings,
  getCoupons,
  removecartProduct,
} from "../../store/reducer/CartReducer";
import debounce from "lodash/debounce";
import _ from "lodash";
import { API_BASE } from "../../services/config";
import LeafImg from "../../assets/img/leaf.png";
import { toast } from "react-toastify";
import { API_STATUS, PAY_METHOD } from "../../utils/Constant";
import {
  checkOut,
  clearLoading,
  displayRazorPay,
  productSelector,
} from "../../store/reducer/ProductReducer";
import {
  getUser,
  homeSelector,
  settingData,
} from "../../store/reducer/HomeReducer";
import { useForm } from "react-hook-form";
import { DeliveryAddress } from "../../utils/Validation";
import { yupResolver } from "@hookform/resolvers/yup";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/DateRangePicker/styles/index.css";
import Select from "react-select/base";

const CartProducts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tax_amount, setTax] = useState();
  const [daddress, setDaddress] = useState(false);
  const [payMethod, setPayMethod] = useState({
    cod: true,
    online: false,
  });
  const [total_amount, setTotal] = useState();
  const [discount, setdiscount] = useState();
  const [discountStyle, setdiscountStyle] = useState({display:"none"});
  const {
    cartProductDatas,
    cartProducttotal,
    addcartLoading,
    RazorpayLoader,
    removecartLoading,
    errorMessage,
  } = useSelector(CartSelector);
  const { paymentpayLoader, SucessPaymentLoading, paymentpayLoad } =
    useSelector(productSelector);
  const { settingDatas, userData } = useSelector(homeSelector);
  console.log(cartProductDatas, settingDatas, userData, "userDssata");
  console.log(userData, "ppd");
// dispatch(getCoupons())
  const [coupon, setCoupon] = useState([
    { coupon_code: "DISC10", price: "10", percent: 0 },
    { coupon_code: "DISC10PERCENT", percent: "10", price: 0 },
  ]);
  const [coupon_code, setCouponCode] = useState();
  useEffect(() => {
    let page = 0;
    let limit = 0;
    let query = "";
    dispatch(cartProductList({ page, limit, query }));
    dispatch(settingData({}));
    dispatch(getUser({}));
  }, []);

  useEffect(() => {
    if(discount ){
      console.log(discount,"dis");
      setdiscountStyle({})
      let taxVal = settingDatas?.tax ? settingDatas?.tax : 18;
      let d=Math.round(discount)
      let taxAmts = (( d/ 100) * parseFloat(taxVal)).toFixed();
      console.log(taxAmts,"dis");
      setTax(taxAmts);
      let cartTotal = (
        parseFloat(discount) + parseFloat(taxAmts)
      ).toFixed();
      setTotal(cartTotal);
    }

    else{
      console.log(discount,"dis");
      setdiscountStyle({display:"none"})
      let taxVal = settingDatas?.tax ? settingDatas?.tax : 18;
    let taxAmts = ((cartProducttotal / 100) * parseFloat(taxVal)).toFixed();
    setTax(taxAmts);
    let cartTotal = (
      parseFloat(cartProducttotal) + parseFloat(taxAmts)
    ).toFixed();
    setTotal(cartTotal);
    }
    
   
  }, [cartProducttotal,discount]);

  const decreseQty = (productData) => {
    if (productData.quantity > 1) {
      const data = [];
      let addData = {};
      let Qty = "-1";
      addData.product_id = productData.product_id;
      addData.quantity = Qty;
      addData.category_id = productData.category_id;
      addData.reg_price = productData.reg_price;
      addData.sale_price = productData.sale_price;
      addData.unit_type = productData.unit_type;
      data.push(addData);
      dispatch(addcartProduct({ payload: data }));
    } else {
      let cartId = {};
      cartId.id = productData.id;
      console.log(cartId, "cartId");
      dispatch(removecartProduct({ cartId }));
    }
  };

  const increseQty = (productData) => {
    const data = [];
    let addData = {};
    addData.product_id = productData.product_id;
    addData.quantity = "1";
    addData.category_id = productData.category_id;
    addData.reg_price = productData.reg_price;
    addData.sale_price = productData.sale_price;
    addData.unit_type = productData.unit_type;
    data.push(addData);
    dispatch(addcartProduct({ payload: data }));
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(DeliveryAddress),
  });
  const handleCoupon=()=>{
    const code=coupon.find((c)=>
     { return c.coupon_code === coupon_code}
      )
    console.log(code,"ccc")
    if(code.percent ===0){
      setdiscount( Number(cartProducttotal) - Number(code.price))
      console.log(discount,"ccc")
    
    }
    if(code.price ===0){
      console.log(code.percent,"ccc")
      let ptotal=(code.percent/100)*cartProducttotal
      console.log(ptotal,"ccc")
      setdiscount(cartProducttotal-ptotal)
      
    }
  }
  const handleCheckOut = (data) => {
    console.log(data, "ppp");
    const isLive = false;
    if (isLive||(localStorage.getItem("username")=="Karthik")) {
      // if (userData?.datas?.address) {
      let datas = {
        total_price: cartProducttotal,
        sub_total: cartProducttotal,
        tax_price: 0,
        name: data.name,
        email: data.email,
        mobile: data.mobile,
        address: data.address,
        city: data.city,
        zipcode: data.zipcode,
        cartDetails: {
          id: cartProductDatas.map((items) => {
            return items.id;
          }),
        },
      };
      console.log(datas, "ooo");
      if (payMethod.cod) {
        datas.type = PAY_METHOD.COD;
        dispatch(checkOut({ datas }));
      } else if (payMethod.online) {
        datas.type = PAY_METHOD.ONLINE;
        dispatch(displayRazorPay({ datas, userData }));
      }
      //  else {
      //   toast.dismiss();
      //   toast.error("Plase Update the Address to Checkout!");
      //   navigate("/my_account");
      // }
    } else {
      toast.error("Temporarily Unavailable to Checkout");
    }
  };
  const removeProduct = (id) => {
    let cartId = {};
    cartId.id = id;
    console.log(cartId, "cartId");
    dispatch(removecartProduct({ cartId }));
  };

  const handlePayCOD = (e) => {
    setPayMethod({
      cod: !payMethod.cod,
      online: !payMethod.online,
    });
  };

  const handlePayOnline = (e) => {
    setPayMethod({
      cod: !payMethod.cod,
      online: !payMethod.online,
    });
  };
  const ordered = (formdata) => {};
  useEffect(() => {
    if (removecartLoading === API_STATUS.FULFILLED) {
      toast.dismiss();
      let page = 0;
      let limit = 0;
      let query = "";
      dispatch(cartProductList({ page, limit, query }));
    }
    if (RazorpayLoader === API_STATUS.FULFILLED) {
      toast.dismiss();
      let page = 0;
      let limit = 0;
      let query = "";
      dispatch(cartProductList({ page, limit, query }));
      dispatch(clearLoading());
    }
    if (SucessPaymentLoading === API_STATUS.FULFILLED) {
      toast.dismiss();
      let page = 0;
      let limit = 0;
      let query = "";
      dispatch(cartProductList({ page, limit, query }));
      navigate("/my_orders");
      toast.success("Your Order is Confirmed");
      dispatch(clearLoading());
    }
    if (paymentpayLoader === API_STATUS.FULFILLED) {
      navigate("/my_orders");
      toast.success("Your Order is Confirmed");
      dispatch(clearLoading());
    }
    if (addcartLoading === API_STATUS.FULFILLED) {
      toast.dismiss();
      let page = 0;
      let limit = 0;
      let query = "";
      dispatch(cartProductList({ page, limit, query }));
    }
    if (errorMessage?.message) toast.error(errorMessage?.message);
    else if (errorMessage?.errors) {
      let data = "";
      console.log(errorMessage, "errorMessage");
      errorMessage?.errors.map((err) => {
        data += err.message + " ";
      });
      toast.error(data);
      dispatch(clearLoadings());
    }
  }, [
    removecartLoading,
    addcartLoading,
    errorMessage,
    RazorpayLoader,
    paymentpayLoader,
    paymentpayLoad,
    SucessPaymentLoading,
  ]);

  useEffect(() => {
    if (daddress === true) {
      reset({
        name: userData.datas.first_name,
        mobile: userData.datas.mobile,
        email: userData.datas.email,
        address: userData.datas.address,
        city: userData.datas.city,
        zipcode: userData.datas.zipcode,
      });
    } else {
      reset({
        name: "",
        mobile: "",
        email: "",
        address: "",
        city: "",
        zipcode: "",
      });
    }
  }, [daddress]);
  return (
    <>
      <Header />

      {/* <!-- HERO SECTION PART START --> */}
      <div className="hero_section">
        <div className="png_img">
          <img className="img-fluid" src={LeafImg} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="herosection_content">
                <h2>Cart Overview</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- SECTION START  --> */}

      {/* <!-- CART OVERVIEW PART START --> */}
      <div class="cart_overview">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 table-responsive">
              <table class="table">
                <thead>
                  <tr class="cartoverview_title">
                    <th>IMAGES</th>
                    <th>PRODUCT</th>
                    <th>PRICE</th>
                    <th>QUANTITY</th>
                    <th>TOTAL</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {cartProductDatas &&
                    cartProductDatas.map((row, index) => {
                      return (
                        <tr>
                          <th scope="row">
                            <div class="thamnail_img">
                              {/* <img class="img-fluid" src="img/overviewimg1.jpg" alt="" /> */}
                              <img
                                class="w-100"
                                src={API_BASE + row?.product?.image}
                                alt=""
                                height="50px"
                                width="50px"
                              />
                            </div>
                          </th>
                          <td class="align-middle">
                            <b>{row?.product?.name}</b>
                            <b>-</b>
                            <b>{row?.unit_type}</b>
                          </td>
                          <td class="align-middle">{row?.sale_price}</td>

                          <td class="align-middle">
                            <div class="cart d-flex">
                              <div class="number-input">
                                <button
                                  onClick={() => decreseQty(row)}
                                ></button>
                                <input
                                  class="quantity"
                                  min="0"
                                  name="quantity"
                                  value={row?.quantity}
                                  type="number"
                                />
                                <button
                                  onClick={() => increseQty(row)}
                                  class="plus"
                                ></button>
                              </div>
                            </div>
                          </td>
                          <td class="align-middle">
                            ₹{parseFloat(row?.total_sale_price).toFixed(2)}
                          </td>
                          <td class="align-middle">
                            <i
                              class="icofont-close text-danger"
                              onClick={() => removeProduct(row?.id)}
                            ></i>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- CART OVERVIEW PART END --> */}

      {/* <!-- COUPON PART START --> */}
      <div className="container mb-3">
        <div className="coupon row">
        <div className="col-6 ">
            
            </div>
          <div className="col-6">
            <div className="row">
              <div className="col-5">
                {/* <label
                  htmlFor="coupon"
                  className="card_total pt-2  text-uppercase  "
                  style={{ backgroundColor: "black" }}
                >
                  Apply Coupon
                </label> */}
              </div>
              {/* <div className="col-4">
               {" "}
                <input
                  type="text"
                  id="promoCode"
                  placeholder="APPLY COUPON"
                  value={coupon_code}
                  onChange={(e) => setCouponCode(e.target.value)}
                  // className=" h-100 w-100 "
                />
              </div> */}
              {/* <div className="col-3">
                {" "}
                <button className="rounded btn btn-succes" onClick={handleCoupon}>Apply</button>
              </div> */}
            </div>
          </div>
          
        </div>
      </div>

      <div class="form">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9">
              {/* <div class="serach_btn">
                {/* <form action="#">
                                    <div class="search_ber">
                                        <input type="text" class="form-control search_button" id="serach_button" name="serach_button" value="" placeholder="Apply Coupon" />
                                        <div class="coupon_btn">
                                            <a href={null} style={{ borderRadius: "5px" }}>APPLY</a> */}
              {/* <a href={null} class="btn border-radius-0 border-transparent">APPLY COUPON</a> */}
              {/* </div>
                                    </div>
                                </form> 
              </div> */}

              <div class="left-form">
                <h3>Order Detials</h3>

                <form onSubmit={handleSubmit(ordered)}>
                  <div className="ml-5 mt-3    ">
                    {" "}
                    <input
                      type="checkbox"
                      checked={daddress}
                      onChange={() => setDaddress(!daddress)}
                      id="default"
                      className=" form-check-input mr-3 "
                    />
                    <label for="default" className="fs-5">
                      {" "}
                      Default address
                    </label>
                  </div>
                  <div class="form_group d-flex">
                    <div class="col-md-6 form_field">
                      <label for="name"> Name </label>
                      <input type="text" name="name" {...register("name")} />
                      <div className="mt-2 p-1">
                        {errors?.first_name?.message && (
                          <p className="error">{errors.first_name.message}</p>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6 form_field">
                      <label for="mobile">Phone</label>
                      <input
                        type="text"
                        name="mobile"
                        {...register("mobile")}
                      />
                      <div className="mt-2 p-1">
                        {errors?.mobile?.message && (
                          <p className="error">{errors.mobile.message}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class="form_group d-flex">
                    <div class="col-md-6 form_field">
                      <label for="email">Email</label>
                      <input type="text" name="email" {...register("email")} />
                      <div className="mt-2 p-1">
                        {errors?.email?.message && (
                          <p className="error">{errors.email.message}</p>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6 form_field">
                      <label for="city">City</label>
                      <input type="text" name="city" {...register("city")} />
                      <div className="mt-2 p-1">
                        {errors?.city?.message && (
                          <p className="error">{errors.city.message}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="form_group d-flex">
                    <div class="col-md-6 form_field">
                      <label for="zipcode">PIN Code</label>
                      <input
                        type="text"
                        name="zipcode"
                        {...register("zipcode")}
                      />
                      <div className="mt-2 p-1">
                        {errors?.zipcode?.message && (
                          <p className="error">{errors.zipcode.message}</p>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6 form_field">
                      <div class="form_field">
                        <label for="address">Address</label>
                        <textarea
                          name="address"
                          {...register("address")}
                        ></textarea>
                        <div className="mt-2 p-1">
                          {errors?.address?.message && (
                            <p className="error">{errors.address.message}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div class="form_group flex justify-flex-start">
                        <input type="submit" value="Submit" name="submit" class="submit_btn" />
                    </div> */}
                </form>
              </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
              <div className="payment_type">
                <h3 className="mb-2">Confirm Payment:</h3>
                <label for="cod">
                  <input
                    type="radio"
                    id="cod"
                    name="payment_type"
                    value="cod"
                    checked={payMethod.cod}
                    onClick={handlePayCOD}
                  />{" "}
                  Cash on Delivery
                </label>

                <label for="razorpay">
                  <input
                    type="radio"
                    id="razorpay"
                    name="payment_type"
                    value="razorpay"
                    checked={payMethod.online}
                    onClick={handlePayOnline}
                  />{" "}
                  Online payment
                </label>
              </div>

              <div class="order_cardTotal">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="card_total text-uppercase"
                        colspan="2"
                      >
                        Cart total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr>
                      <th scope="row" class="subtotal">
                        Subtotal:
                      </th>
                      <td class="text-right">₹{cartProducttotal}</td>
                    </tr>
                    <tr style={discountStyle}>
                      <th scope="row" class="subtotal">
                        Discount:
                      </th>
                      <td class="text-right">₹{discount}</td>
                    </tr>
                    <tr>
                      <th scope="row" class="discount">
                        Tax:
                      </th>
                      <td class="text-right">₹{tax_amount}</td>
                    </tr> */}
                    <tr>
                      <th scope="row">Total:</th>
                      <td class="text-right total_num">₹{cartProducttotal}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {cartProducttotal > 0 ? (
                <div class="chechout_btn text-right">
                  <a
                    href={null}
                    onClick={handleSubmit(handleCheckOut)}
                    class="btn border-radius-0 border-transparent"
                  >
                    Checkout
                  </a>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- COUPON PART END --> */}
      {/* <!-- SECTION END --> */}
      <Footer />
    </>
  );
};

export default CartProducts;
