import React, { useRef, useState, useEffect, useCallback } from "react";
import Select from "react-select";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  productSelector,
  categoryList,
  productList,
  filterData,
} from "../../store/reducer/ProductReducer";
import {
  CartSelector,
  cartProductList,
  removecartProduct,
  addcartProduct,
  clearLoadings,
} from "../../store/reducer/CartReducer";
import debounce from "lodash/debounce";
import _ from "lodash";
import { API_BASE } from "../../services/config";
import { toast } from "react-toastify";
import { API_STATUS } from "../../utils/Constant";
import { homeSelector, settingData } from "../../store/reducer/HomeReducer";
import Header from "../Layout/header";
import Footer from "../Layout/footer";

const Products = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [range, setRange] = useState(3);
  const [showdata, setshowdata] = useState(0);
  const [fquery, setFQuery] = useState([]);
  const [prices, setPrices] = useState("800");
  const rangeRef = useRef(0);
  const rangeVRef = useRef(0);
  const { settingDatas } = useSelector(homeSelector);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedData, setSelectedData] = useState({
    unit_type: '',
    reg_price: '',
    sale_price: '',
  });
/* Selector */
  const {
    categoryDatas,
    productDatas,
    productFilterDatas,
    productLoading,
    catgLoading,
  } = useSelector(productSelector);
  const {
    cartProductDatas,
    cartProducttotal,
    cartLoading,
    removecartLoading,
    addcartLoading,
    errorMessage,
  } = useSelector(CartSelector);

  /* Functions */
  const handleChange = (event) => {
    setPrices(event.target.value);
    const newValue = Number(
        ((event.target.value - rangeRef.current.min) * 100) /
          (rangeRef.current.max - rangeRef.current.min)
      ),
      newPosition = 10 - newValue * 0.2;
    rangeVRef.current.classList.remove("range-valueX");
    rangeVRef.current.innerHTML = `<span>${event.target.value}</span>`;
    rangeVRef.current.style.left = `calc(${newValue}% + (${newPosition}px))`;
    console.log(event.target.value, "ONPRICES");
    filterFun(fquery, event.target.value);
  };

  const handleOnSearch = (event) => {
    const { id } = event.target;
    const { checked } = event.target;
    let category_ids = fquery;
    if (checked) {
      category_ids.push(id);
    } else {
      category_ids = category_ids.filter((items) => {
        return items != id;
      });
    }
    setFQuery(category_ids);
    filterFun(category_ids, prices);
  };

  const filterFun = (fquery, prices) => {
    let filterArray = {};
    filterArray.category_ids = fquery;
    filterArray.prices = prices;
    dispatch(filterData({ filterArray }));
    setshowdata(1);
  };

  const addcartProducts = (row) => {
    if (localStorage.getItem("isAuthenticated")) {
      const data = [];
      let addData = {};
      addData.product_id = row.id;
      addData.quantity = "1";
      addData.category_id = row.catgory_id;
      addData.reg_price = selectedData.reg_price;
      addData.sale_price = selectedData.sale_price;
      addData.unit_type=selectedData?.unit_type
      data.push(addData);
      console.log(data, "fklsdfj")
      dispatch(addcartProduct({ payload: data }));
    } else {
      navigate("/signin");
      toast.warning("Signin to continue...");
    }
  };
  const handleSelectChange = (selectedOption, productId) => {
    const [unit, regPrice, salePrice] = selectedOption.value.split(',');
    console.log(unit, regPrice, salePrice, "cllsfk")
    setSelectedData({
      reg_price:regPrice,
      sale_price:salePrice,
      unit_type:unit
    })
    setSelectedOption((prevSelected) => ({
      ...prevSelected,
      [productId]: selectedOption,
    }));

   
  };
  /* useEffect */

  useEffect(() => {
    setPrices(settingDatas?.max_range ?? "800");
  }, [settingDatas]);

  useEffect(() => {
    let page = 0;
    let limit = 0;
    let query = "";

    dispatch(categoryList({ page, limit, query }));
    dispatch(productList({ page, limit, query }));
    dispatch(settingData({}));
  }, []);

  useEffect(() => {
    console.log(categoryDatas, "categoryDatas");
  }, [categoryDatas]);

  useEffect(() => {
    console.log(productDatas, "productDatas");
  }, [productDatas]);
  useEffect(() => {
    console.log(productFilterDatas, "productFilterDatas");
  }, [productFilterDatas]);

  useEffect(() => {
    if (addcartLoading === API_STATUS.FULFILLED) {
      toast.dismiss();
      toast.success("Product added in the Cart Successfully!");
      let page = 0;
      let limit = 0;
      let query = "";
      dispatch(cartProductList({ page, limit, query }));
      dispatch(clearLoadings());
    }
    if (errorMessage?.message) toast.error(errorMessage?.message);
    else if (errorMessage?.errors) {
      let data = "";
      console.log(errorMessage, "errorMessage");
      errorMessage?.errors.map((err) => {
        data += err.message + " ";
      });
      toast.error(data);
      // dispatch(clearLoadings());
    }
  }, [addcartLoading, errorMessage]);

  return (
    <>
      <Header />

      {/* <!-- HERO SECTION PART START --> */}

      <div class="hero_section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="herosection_content">
                <h2>Products</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- SECTION START  --> */}

      <section class="pad-80 product_listings">
        <div class="container">
          <div class="row">
            <div class="col-lg-3">
              <div class="sidebar">
                <div class="sidebar-section">
                  <div class="sidebar-wrapper">
                    <h5 class="wrapper-heading">Product Categories</h5>
                    <div class="sidebar-item">
                      <ul class="sidebar-list">
                        {categoryDatas &&
                          categoryDatas.map((row, index) => {
                            return (
                              <li>
                                <input
                                  type="checkbox"
                                  id={row?.id}
                                  name="category[]"
                                  onClick={handleOnSearch}
                                />
                                <label for={row?.id}>{row?.name}</label>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>

                  <div class="sidebar-wrapper sidebar-range">
                    <h5 class="wrapper-heading">Price Range</h5>
                    <div class="price-slide range-slider">
                      <div class="price">
                        <div class="range-wrap">
                          <div
                            class="range-value range-valueX"
                            id="rangeV"
                            ref={rangeVRef}
                          >
                            <span>{prices}</span>
                          </div>
                          <input
                            type="range"
                            ref={rangeRef}
                            min={settingDatas?.min_range ?? 200}
                            max={settingDatas?.max_range ?? 800}
                            defaultValue={800}
                            onChange={handleChange}
                            step="1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-9">
              <h3 class="section-title">Our Products</h3>
              {showdata == 1 ? (
                <div className="row">
                  {productFilterDatas?.length > 0 ? (
                    productFilterDatas.map((row, index) => {
                      const options = row.unit_type
                      ? row.unit_type.map((unit) => {
                          const unitLabel =
                            unit < 1000 ? `${unit}g` : `${unit / 1000} kg`;
                          const salePricePerGram =
                            parseFloat(row.sale_price) / 1000;
                          const regPricePerGram =
                            parseFloat(row.reg_price) / 1000;

                          const salePrice = (salePricePerGram * unit).toFixed(
                            2
                          );
                          const regPrice = (regPricePerGram * unit).toFixed(
                            2
                          );
                          return {
                            label: (
                              <div className="select-option">
                                <span className="unit-label">
                                  {unitLabel}
                                </span>
                                <span className="price">₹{salePrice}</span>
                                <span className="reg-price">₹{regPrice}</span>
                              </div>
                            ),
                            value: `${unit}g,${regPrice},${salePrice} `,
                          };
                        })
                      : [];

                      return (
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4">
                          <div class="product">
                            <div class="product-img">
                              <img
                                class="w-100"
                                src={API_BASE + row?.image}
                                alt=""
                              />
                            </div>
                            <div class="product-content">
                              <div class="product-details">
                                <div class="product-name">
                                  <Link
                                    to={
                                      "/product_details/" +
                                      row.id +
                                      "/" +
                                      row.catgory_id
                                    }
                                  >
                                    {" "}
                                    {row?.name}
                                  </Link>
                                </div>
                                <div class="product-flex">
                                  {/* <div class="left">
                                    <span class="product-prev-price">
                                      ₹{row?.reg_price}
                                    </span>
                                    <span class="product-price">
                                      ₹{row?.sale_price}
                                    </span>
                                  </div> */}
                                   <div class="left">
                                    {/* <span class="product-prev-price">₹{row?.reg_price}</span>
                                                                        <span class="product-price">₹{row?.sale_price}</span> */}
                                    <div className="dropdown-container">
                                      <Select
                                    //  value={selectedOption}  
                                     options={options}
                                     value={selectedOption[row.id]}
                                     onChange={(option) =>
                                      handleSelectChange(option, row.id)
                                    }
                                        isSearchable={false}
                                        classNamePrefix="react-select"
                                        placeholder="Select Package"
                                        className="react-select"
                                      />
                                      <button className="  add-button btn1"
                                      onClick={() => addcartProducts(row)}
                                      >
                                        Add
                                      </button>
                                    </div>
                                  </div>
                                  {/* <div class="right">
                                    <button
                                      class="btn custom-btn"
                                      onClick={() => addcartProducts(row)}
                                    >
                                      {" "}
                                      Add
                                    </button>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>No Products...</div>
                  )}
                </div>
              ) : (
                <div className="row">
                  {productDatas &&
                    productDatas.map((row, index) => {
                      const options = row.unit_type
                        ? row.unit_type.map((unit) => {
                            const unitLabel =
                              unit < 1000 ? `${unit}g` : `${unit / 1000} kg`;
                            const salePricePerGram =
                              parseFloat(row.sale_price) / 1000;
                            const regPricePerGram =
                              parseFloat(row.reg_price) / 1000;

                            const salePrice = (salePricePerGram * unit).toFixed(
                              2
                            );
                            const regPrice = (regPricePerGram * unit).toFixed(
                              2
                            );
                            return {
                              label: (
                                <div className="select-option">
                                  <span className="unit-label">
                                    {unitLabel}
                                  </span>
                                  <span className="price">₹{salePrice}</span>
                                  <span className="reg-price">₹{regPrice}</span>
                                </div>
                              ),
                              value: `${unit}g,${regPrice},${salePrice} `,
                            };
                          })
                        : [];

                      return (
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4">
                          <div class="product">
                            <div class="product-img">
                              <img
                                class="w-100"
                                src={API_BASE + row?.image}
                                alt=""
                              />
                            </div>
                            <div class="product-content">
                              <div class="product-details">
                                <div class="product-name">
                                  <Link
                                    to={
                                      "/product_details/" +
                                      row.id +
                                      "/" +
                                      row.catgory_id
                                    }
                                  >
                                    {row?.name}
                                  </Link>
                                </div>
                                <div class="product-flex">
                                  <div class="left">
                                    {/* <span class="product-prev-price">₹{row?.reg_price}</span>
                                                                        <span class="product-price">₹{row?.sale_price}</span> */}
                                    <div className="dropdown-container">
                                      <Select
                                    //  value={selectedOption}  
                                     options={options}
                                     value={selectedOption[row.id]}
                                     onChange={(option) =>
                                      handleSelectChange(option, row.id)
                                    }
                                        isSearchable={false}
                                        classNamePrefix="react-select"
                                        placeholder="Select Package"
                                        className="react-select"
                                      />
                                      <button className="  add-button btn1"
                                      onClick={() => addcartProducts(row)}
                                      >
                                        Add
                                      </button>
                                    </div>
                                  </div>
                                  {/* <div class="right">
                                    <button
                                      class="btn custom-btn"
                                      onClick={() => addcartProducts(row)}
                                    >
                                      {" "}
                                      Add
                                    </button>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* <!-- SECTION END --> */}
      <Footer />
    </>
  );
};

export default Products;
