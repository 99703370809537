import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constant";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";
import { secretKey } from "../../services/config";
import {
  cartData,
  delcartProduct,
  createcartProduct,
  orderList,
  coupons,
  pdfprint,
} from "../../services/api";
import alertmessage from "./../../utils/alert";
import { toast } from "react-toastify";
const namespace = "CartReducer";

const initialState = {
  loading: "initial",
  errorMessage: "",
  cartLoading: "initial",
  removecartLoading: "initial",
  orderListLoader: "initial",
  addcartLoading: "initial",
  count: 0,
  cartProductDatas: [],
  cartProducttotal: [],
  orderData: [],
  getCouponLoading: false,
  allCoupons: [],
  pdfMessage: "",
  generateLoading: false,
};

export const cartProductList = createAsyncThunk(
  `${namespace}/cartProductList`,
  async ({ page, limit, query }, { rejectWithValue, dispatch }) => {
    try {
      console.log(page, limit, query, "SDFASDFD");
      const { data } = await cartData(page, limit, query);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const addcartProduct = createAsyncThunk(
  `${namespace}/addcartProduct`,
  async (addData, { rejectWithValue, dispatch }) => {
    try {
      console.log(addData, "addDataSDFASDFD");
      const { data } = await createcartProduct(addData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getOrders = createAsyncThunk(
  `${namespace}/getorders`,
  async ({ query, limit, page }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await orderList(query, limit, page);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const removecartProduct = createAsyncThunk(
  `${namespace}/removecartProduct`,
  async ({ cartId }, { rejectWithValue, dispatch }) => {
    try {
      console.log(cartId, "SDFASDFD");
      const { data } = await delcartProduct(cartId);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const getCoupons = createAsyncThunk(
  `${namespace}/getCoupons`,
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await coupons();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const generatePdf = createAsyncThunk(
  `${namespace}/generatepdf`,
  async (ID, { rejectWithValue, dispatch }) => {
    try {
      const data = await pdfprint(ID);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
const CartSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
    clearLoadings: (state) => {
      state.removecartLoading = "initial";
      state.cartLoading = "initial";
      state.addcartLoading = "initial";
      state.errorMessage = "initial";
    },
    clearPdfData: (state) => {
      state.pdfMessage = "";
      state.generateLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(cartProductList.pending, (state) => {
      state.cartLoading = API_STATUS.PENDING;
    });
    builder.addCase(cartProductList.fulfilled, (state, { payload }) => {
      state.cartLoading = API_STATUS.FULFILLED;
      console.log(payload, "cartProductListD");
      // let payloaddatas =
      //     JSON.parse(EncryptDecrypt.decryptdata(payload.data, secretKey))
      // state.cartProductDatas = payloaddatas?.data;

      state.cartProductDatas = payload?.datas?.data;
      state.cartProducttotal = payload?.datas?.totalAmt;
    });
    builder.addCase(cartProductList.rejected, (state, action) => {
      state.cartLoading = API_STATUS.REJECTED;
      if (action?.payload?.data?.auth === "deleted") {
        alertmessage.sweetalert(action?.payload?.data?.message);
      }
      state.errorMessage = action?.payload?.data;
      console.log(action.payload.data, "the reejce");
    });
    builder.addCase(addcartProduct.pending, (state) => {
      state.addcartLoading = API_STATUS.PENDING;
    });
    builder.addCase(addcartProduct.fulfilled, (state, { payload }) => {
      state.addcartLoading = API_STATUS.FULFILLED;
      console.log(payload, secretKey, "addcartProduct");
      // let payloaddatas =
      //     JSON.parse(EncryptDecrypt.decryptdata(payload.data, secretKey))
      // state.cartProductDatas = payloaddatas?.data;

      // state.cartProductDatas = payload?.datas?.data;
      // state.cartProducttotal = payload?.datas?.count;
    });
    builder.addCase(addcartProduct.rejected, (state, action) => {
      state.addcartLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(removecartProduct.pending, (state) => {
      state.removecartLoading = API_STATUS.PENDING;
    });
    builder.addCase(removecartProduct.fulfilled, (state, { payload }) => {
      state.removecartLoading = API_STATUS.FULFILLED;
      // let payloaddatas =
      //     JSON.parse(EncryptDecrypt.decryptdata(payload.data, secretKey))
      // state.cartProductDatas = payloaddatas?.data;

      // state.cartProductDatas = payload?.datas?.data;
      // state.cartProducttotal = payload?.datas?.count;
    });
    builder.addCase(removecartProduct.rejected, (state, action) => {
      state.removecartLoading = action?.payload?.data;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(getOrders.rejected, (state, action) => {
      state.orderListLoader = action?.payload?.data;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(getOrders.fulfilled, (state, { payload }) => {
      state.orderListLoader = payload?.data;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload.datas, secretKey)
      );
      console.log(payloaddatas, "orders payload details");
      state.orderData = payloaddatas?.data;
    });
    builder.addCase(getOrders.pending, (state, action) => {
      state.orderListLoader = action?.payload?.data;
      state.errorMessage = action?.payload?.data;
    });

    builder.addCase(getCoupons.rejected, (state, action) => {
      state.getCouponLoading = action?.payload?.data;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(getCoupons.fulfilled, (state, { payload }) => {
      state.getCouponLoading = payload?.data;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload.datas, secretKey)
      );
      console.log(payloaddatas, "coupons details");
      state.allCoupons = payloaddatas?.data;
    });
    builder.addCase(getCoupons.pending, (state, action) => {
      state.orderListgetCouponLoadingLoader = action?.payload?.data;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(generatePdf.pending, (state) => {
      state.generateLoading = API_STATUS.PENDING;
      toast.warning("PDF loading please wait");
    });
    builder.addCase(generatePdf.fulfilled, (state, { payload }) => {
      state.generateLoading = API_STATUS.FULFILLED;
      state.pdfMessage = payload.data?.file_name;
      toast.success("PDF opened in new tab");
    });
    builder.addCase(generatePdf.rejected, (state, action) => {
      state.generateLoading = API_STATUS.REJECTED;
    });
  },
});

export const { clearData, clearLoadings, clearPdfData } = CartSlice.actions;

export const CartSelector = (state) => state.CartReducer;

export default CartSlice.reducer;
