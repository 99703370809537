import React, { useEffect } from "react";
import Header from "./Layout/header";
import Footer from "./Layout/footer";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { surveyValidationSchema } from "../utils/Validation";
import { useDispatch, useSelector } from "react-redux";
import {
  Surveys,
  clearMessages,
  homeSelector,
  settingData,
} from "../store/reducer/HomeReducer";
import { toast } from "react-toastify";

const Survey = () => {
  const dispatch = useDispatch();
  const { surveysuccessMessage } = useSelector(homeSelector);
  const { settingDatas } = useSelector(homeSelector);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(surveyValidationSchema),
    defaultValues: {
      usage: "<1 kg",
      fresh: "Average",
      frequent: "Twice a Week",
      spent: "250 to 350", // Set the default value here
      phone_number: "+91",
    },
  });
  console.log(errors, "error");
  const submitHanlder = (formData) => {
    console.log(formData);
    // return false;
    dispatch(Surveys({ formData }));
  };

  useEffect(() => {
    if (surveysuccessMessage !== "") {
      toast.success(surveysuccessMessage);
      dispatch(clearMessages());
      reset();
    }
  }, [surveysuccessMessage]);

  useEffect(() => {
    dispatch(settingData({}));
  }, []);

  return (
    <div>
      <Header />
      <div className="hero_section" style={{ paddingBottom: "25px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="herosection_content">
                <h2>Survey</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="contact-form">
        <div className="container">
          {/* <h2>Message Us</h2> */}
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="left-form">
                <form onSubmit={handleSubmit(submitHanlder)}>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label className="font-weight-bolder">Name</label>
                      <br />
                      <input
                        type="text"
                        style={{ marginBottom: "10px" }}
                        placeholder="Your Name..."
                        {...register("name")}
                      />{" "}
                      <br />
                      {errors?.name?.message && (
                        <p className="error">{errors.name.message}</p>
                      )}
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label className="font-weight-bolder">
                        Whatsapp Number
                      </label>
                      <br />
                      <input
                        type="text"
                        style={{ marginBottom: "10px" }}
                        placeholder="Your Whatsapp Number..."
                        {...register("phone_number", {
                          pattern: {
                            value: /^(?:\+91)?[0-9]{10}$/,
                            message: "Invalid phone number format",
                          },
                        })}
                      />
                      {errors?.phone_number?.message && (
                        <p className="error">{errors.phone_number.message}</p>
                      )}
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label className="font-weight-bolder">Email</label>
                      <br />
                      <input
                        type="text"
                        style={{ marginBottom: "10px" }}
                        placeholder="Your Email..."
                        {...register("email")}
                      />
                      <br />
                      {errors?.email?.message && (
                        <p className="error">{errors.email.message}</p>
                      )}
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label className="font-weight-bolder">Total Family</label>
                      <br />
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
                          <select
                            style={{ marginBottom: "10px" }}
                            placeholder="Kids(Up to 13 yrs)"
                            {...register("kids")}
                          >
                            <option value={""}>Select Kids</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                          </select>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                          <select
                            style={{ marginBottom: "10px" }}
                            placeholder="Adults(Up to 40 yrs)"
                            {...register("adults")}
                          >
                            <option value={""}>Select Adults</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                          </select>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                          <select
                            style={{ marginBottom: "10px" }}
                            placeholder="Older(>40 yrs)"
                            {...register("older")}
                          >
                            <option value={""}>Select Elders</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                          </select>
                        </div>
                      </div>
                      <br />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-3">
                      <label className="font-weight-bolder">
                        Vegetables usage in a week
                      </label>
                      <br />
                      <input
                        type="radio"
                        id="check1"
                        style={{ marginBottom: "10px" }}
                        value="<1 kg"
                        {...register("usage")}
                      />
                      <label className="mx-3" for="check1">
                        {"<"} 1 Kg
                      </label>
                      <input
                        type="radio"
                        value="1 to 2 kg"
                        style={{ marginBottom: "10px" }}
                        id="check2"
                        {...register("usage")}
                      />
                      <label className="mx-3" for="check2">
                        1-2 Kg
                      </label>
                      <input
                        type="radio"
                        id="check3"
                        style={{ marginBottom: "10px" }}
                        value=">2 kg"
                        {...register("usage")}
                      />
                      <label className="mx-3" for="check3">
                        {">"} 2 Kg
                      </label>
                      <br />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label className="font-weight-bolder">
                        How fresh are the Vegetables
                      </label>
                      <br />
                      <input
                        type="radio"
                        id="check4"
                        style={{ marginBottom: "10px" }}
                        value="Average"
                        {...register("fresh")}
                      />
                      <label className="mx-3" for="check4">
                        Average
                      </label>
                      <input
                        type="radio"
                        id="check41"
                        style={{ marginBottom: "10px" }}
                        value="Poor"
                        {...register("fresh")}
                      />
                      <label className="mx-3" for="check41">
                        Poor
                      </label>
                      <input
                        type="radio"
                        id="check5"
                        style={{ marginBottom: "10px" }}
                        value="Very Fresh"
                        {...register("fresh")}
                      />
                      <label className="mx-3" for="check5">
                        Very Fresh
                      </label>
                      <br />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12  mb-3">
                      <label className="font-weight-bolder">
                        How frequently you like to have fresh vegetables in a
                        week
                      </label>
                      <br />
                      <input
                        type="radio"
                        id="check6"
                        style={{ marginBottom: "10px" }}
                        value="Twice a Week"
                        {...register("frequent")}
                      />
                      <label className="mx-3" for="check6">
                        Twice a Week
                      </label>
                      <input
                        type="radio"
                        value="Once a Week"
                        style={{ marginBottom: "10px" }}
                        id="check7"
                        {...register("frequent")}
                      />
                      <label className="mx-3" for="check7">
                        Once a Week
                      </label>
                      <br />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label className="font-weight-bolder">
                        Amount spent on vegetables for a week
                      </label>
                      <br />
                      <input
                        type="radio"
                        id="check8"
                        style={{ marginBottom: "10px" }}
                        value="250 to 350"
                        {...register("spent")}
                      />
                      <label className="mx-3" for="check8">
                        ₹250-₹350
                      </label>
                      <input
                        type="radio"
                        value="350 to 650"
                        style={{ marginBottom: "10px" }}
                        id="check9"
                        {...register("spent")}
                      />
                      <label className="mx-3" for="check9">
                        ₹350-₹650
                      </label>
                      <input
                        type="radio"
                        value="Above 650"
                        style={{ marginBottom: "10px" }}
                        id="check10"
                        {...register("spent")}
                      />
                      <label className="mx-3" for="check10">
                        {">"} ₹650
                      </label>
                      <br />
                    </div>
                    <input
                      className="submit-btn m-auto"
                      type="submit"
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
            </div>

            {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="right-form">
                <p><b>Have any queries?</b><br />We're here to help. Your connection to solutions and assistance, available at your convenience.</p>
                <div className="hr"></div>
                <ul><li><i className="lni lni-map-marker"></i><span style={{ display: "flex" }}>{settingDatas?.address}</span></li>
                  <li><i className="lni lni-phone"></i><span>{settingDatas?.contact}</span></li>
                  <li><i className="lni lni-envelope"></i><span>{settingDatas?.gmail}</span></li></ul>
                <iframe className="map-dig" src={settingDatas?.map_url}></iframe>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Survey;
